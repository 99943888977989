export const dayMonthFormattor = (date) => {
    const dateTokens = date.split("-")
    const day = dateTokens[2]
    let month = ""
    switch (dateTokens[1]) {
        case "01":
            month = "Jan"
            break;
        case "02":
            month = "Feb"
            break;
        case "03":
            month = "Mar"
            break;
        case "04":
            month = "Apr"
            break;
        case "05":
            month = "May"
            break;
        case "06":
            month = "Jun"
            break;
        case "07":
            month = "Jul"
            break;
        case "08":
            month = "Aug"
            break;
        case "09":
            month = "Sep"
            break;
        case "10":
            month = "Oct"
            break;
        case "11":
            month = "Nov"
            break;
        default:
            month = "Dec"
    }
    return day + ' ' + month
}

export const kFormatter = (num) => {
	if (Math.abs(num) > 1e6) {
		return `${Math.sign(num) * (Math.abs(num) / 1e6).toFixed(2)}M`
	}
	return Math.abs(num) > 999
		? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(2)}K`
		: Math.abs(num) > 0 ? Number((Math.sign(num) * Math.abs(num)).toFixed(2)) : 0
}
