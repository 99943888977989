import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import uuid from "react-uuid"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

function StoredCredentialsChartFive({ data }) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [rows, setRows] = useState([])
  
  useEffect(()=>{
    if(data) {
      let newSeries = []
      data.time_series.forEach(item=>{
        const temp = { 
          intent: item.intent,
          count: item.count,
          status: item.status
        }
        newSeries.push(temp)
      })
      setRows(newSeries)
    }
  },[])
  return (
    <Item>
      <>
        <Typography gutterBottom variant="h5" component="div">
          Decline Codes by Stored Credential
        </Typography>
        <TableContainer component={Paper}  style={{height:'340px'}}>
          <Table sx={{ minWidth: 320 }} aria-label="a dense table" >
            <TableHead>
              <TableRow>
                <TableCell>Intent</TableCell>
                <TableCell align="right">Pagos Short Code</TableCell>
                <TableCell align="right">Total Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={`${row.intent}-${Math.random()}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.intent}
                  </TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Item>
  );
}

export default StoredCredentialsChartFive;
