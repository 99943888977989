// import axios from "axios";
export const loginCall = async (userCredential, dispatch) => {
  dispatch({ type: "LOGIN_START" });
  dispatch({ type: "LOGIN_SUCCESS", payload: userCredential.token });
  // axios.get("https://clients-gateway.dev.pagosapi.com/identity/profile",{
  //     headers: {
  //         Authorization: `Bearer ${userCredential.token}`
  //     }
  // }).then(res=>{
  //   dispatch({ type: "LOGIN_SUCCESS", payload: userCredential.token });
  // }).catch (err=> {
  //   dispatch({ type: "LOGIN_FAILURE", payload: err });
  // })
};
export const logoutCall = async (dispatch) => {
  dispatch({ type: "LOGOUT" }); 
};
