/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react"
import * as echarts from "echarts"

export const Chart = ({ options }) => {
	const [chartObject, setChart] = useState(null)
	const [chartOptions, setOptions] = useState(null)
	const myChart = useRef(null)

	useEffect(() => {
		const chart = echarts.init(myChart.current)
		chart.setOption({})
		setChart(chart)
	}, [])
	
	useEffect(() => {
		const handleResize = () => {
			chartObject.resize()
		}
		
		window.addEventListener("resize", handleResize)

		// cleanup the events after the chart is disposed
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [chartObject])

	useEffect(() => {
		setOptions({ options })
		if (chartOptions && chartObject) {
			chartObject.setOption(options, true, false)

			chartObject.resize()

			return () => {
				chartObject.dispatchAction({
					type: "hideTip"
				})
			}
		}
	}, [options, chartObject])
	
	return (
		<div
			ref={myChart}
			style={{
             	width: options.blockStyle ? options.blockStyle.width : "100%",
             	height: options.blockStyle ? options.blockStyle.height : "100%"
			}}
		/>
	)
}
