import { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import { kFormatter } from '../../../utils';
import { Typography } from '@mui/material';

function splitArrayIntoCommonKeysUsingPPM(data){
  return data.time_series.reduce(function(newArr, obj) {  
        var key = obj.pm_card_issuer_country;                               
        var newObj = {
          currency: obj.currency,
          count: obj.count,
          status: obj.status,
          pm_card_issuer_country: obj.pm_card_issuer_country,
        };   
        if(newArr[key])                                               
          newArr[key].push(newObj);                                   
        else                                                         
          newArr[key] = [ newObj ];                                    
        return newArr
      }, {});
}
function splitArrayIntoCommonKeysUsingStatus(data){
  return data.time_series.reduce(function(newArr, obj) {  
        var key = obj.status;                               
        var newObj = {
          currency: obj.currency,
          count: obj.count,
          status: obj.status,
          pm_card_issuer_country: obj.pm_card_issuer_country,
        };   
        if(newArr[key])                                               
          newArr[key].push(newObj);                                   
        else                                                         
          newArr[key] = [ newObj ];                                    
        return newArr
      }, {});
}
function DeclineAnalysisChartSix({ data }) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [chartOptions, setChartOptions] = useState({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {},
    grid: {
      left: '5%',
      right: '4%',
      bottom: '3%',
      top: '20%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        margin: 25,
        formatter: function (value, index) {
          return kFormatter(value);
        }
      }
    },
    series: []
  })

  useEffect(() => {
    if (data) {
      const splitObj = splitArrayIntoCommonKeysUsingStatus(data)
      const legends = Object.keys(splitObj)

      const splitObj1 = splitArrayIntoCommonKeysUsingPPM(data)
      const pm = Object.keys(splitObj1)
      let xValues = []
      pm.forEach(item => {
        xValues.push(item)
      })
      let seriesArr = []
      legends.forEach((legend,index)=>{
          let singleSeries = {
            name: legend,
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: []
          }
          splitObj[legend].forEach(item => {
            singleSeries.data.push(item.count)
          })
          seriesArr.push(singleSeries)
      })
      setChartOptions({
        ...chartOptions,
        legend: {
          data: legends,
          type: 'scroll'
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: xValues,
        },
        series: seriesArr
      })
    }
  }, [data])
  return (
    <Item>{chartOptions ? <>
      <Typography gutterBottom variant="h5" component="div">
      Approval by Issuer Country
    </Typography>
    <ReactECharts option={chartOptions} />
    </> : 'No Data'}</Item>
  );
}

export default DeclineAnalysisChartSix;
