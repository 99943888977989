import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AuthContext } from '../../../context/AuthContext';
import DateRangeFilter from '../../date-range-filter/date-range-filter';
import FlagsChartFour from './flags-chart-four';
import FlagsChartThree from './flags-chart-three';
import FlagsChartTwo from './flags-chart-two';
import FlagsChartOne from './flags-chart-one';
import FlagsChartFive from './flags-chart-five';

const pChartsParser = require('peacock-charts-sdk')
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '370px',
  color: theme.palette.text.secondary,
}));
function removeTokenAndReload() {
  localStorage.removeItem("token")
  window.location.reload()
}
const dateToday = new Date()
function Flags() {

  const { token } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(new Date(dateToday.getTime() - (6 * 24 * 60 * 60 * 1000)));
  const [endDate, setEndDate] = useState(dateToday);
  const [chart1Data, setChart1Data] = useState(null)
  const [chart1Loading, setChart1Loading] = useState(true)
  const [params, setParams] = useState({
    frequency: 'daily',
    conversion_currency_code: 'usd',
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  })
  const [chart2Data, setChart2Data] = useState(null)
  const [chart2Loading, setChart2Loading] = useState(true)

  const [chart3Data, setChart3Data] = useState(null)
  const [chart3Loading, setChart3Loading] = useState(true)

  const [chart4Data, setChart4Data] = useState(null)
  const [chart4Loading, setChart4Loading] = useState(true)

  const [chart5Data, setChart5Data] = useState(null)
  const [chart5Loading, setChart5Loading] = useState(true)


  useEffect(() => {
    pChartsParser.pChart40TransactionFlagDetails('DEV', token, params).then(res => {
      if (res !== null) {
        setChart1Data(res.data_bag)
        setChart1Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart41CvvFlagApprovalTrends('DEV', token, params).then(res => {
      if (res !== null) {
        setChart2Data(res.data_bag)
        setChart2Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart42AvsFlagApprovalTrends('DEV', token, params).then(res => {
      if (res !== null) {
        setChart3Data(res.data_bag)
        setChart3Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart43TransactionStatusToCvvBreakdown('DEV', token, params).then(res => {
      if (res !== null) {
        setChart4Data(res.data_bag)
        setChart4Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })

    pChartsParser.pChart44TransactionStatusToAvsBreakdown('DEV', token, params).then(res => {
      if (res !== null) {
        setChart5Data(res.data_bag)
        setChart5Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })

  }, [params])
  return (
    <Container maxWidth="xl">
      <Card sx={{ marginTop: '16px', padding: '16px 8px' }}>
        <Typography gutterBottom variant="h5" component="div">
          Flags
        </Typography>
        <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setParams={setParams} />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6} lg={4}>
            {!chart1Loading ? <FlagsChartOne data={chart1Data} /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart2Loading ? <FlagsChartTwo data={chart2Data} /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart3Loading ? <FlagsChartThree data={chart3Data} /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart4Loading ? <FlagsChartFour valueField={"count"}
              leftField={"pagos_code_short"}
              rightField={"checks_cvc_check"}
              requestData={chart4Data} /> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart5Loading ? <FlagsChartFive valueField={"count"}
              leftField={"pagos_code_short"}
              rightField={"checks_avs_check"}
              requestData={chart5Data} /> : <Item>Loading</Item>}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

export default Flags;
