import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Chart } from '../../shared/chart/chart'
import { Typography } from '@mui/material';
import { DefaultColors } from "../../../shared/charts-color-palette/color-palette"

const getChartConfig = (uniquesKeys = [], links = [], colors = {}) => ({
	color: DefaultColors,
  blockStyle:{
    height:300
  },
	tooltip: {
		trigger: "item",
		appendToBody: true,
		transitionDuration: 0
	},
	grid: {
		containLabel: true,
		top: 30,
		left: 65,
		right: 65,
		bottom: 30
	},
	series: {
		type: "sankey",
		layout: "none",
		emphasis: {
			focus: "adjacency"
		},
		data: uniquesKeys.map((key) => ({
			name: key,
			itemStyle: { color: colors[key] }
		})),
		links
	}
})
function FlagsChartFive({
  requestData = {},
	valueField = "count",
	leftField = "status",
	rightField = "network",
	colors = {}
}) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const { time_series: data } = requestData

	const uniquesKeys = new Set()

	const links = data.map((item) => {
		if (item[leftField] === "unknown") {
			item[leftField] += `_${leftField}`
		}

		if (item[rightField] === "unknown") {
			item[rightField] += `_${rightField}`
		}

		uniquesKeys.add(item[leftField])
		uniquesKeys.add(item[rightField])

		return {
			source: item[leftField],
			target: item[rightField],
			value: item[valueField]
		}
	})

  return (
    <Item>
        <Typography gutterBottom variant="h5" component="div">
        Transaction Status to AVS Breakdown
        </Typography>
        <Chart options={getChartConfig([...uniquesKeys], links, colors)} />
    </Item>
  );
}

export default FlagsChartFive;
