import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LineChartOne from '../charts/line-chart-one';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LineChartTwo from '../charts/line-chart-two';
import LineChartThree from '../charts/line-chart-three';
import LineChartFive from '../charts/line-chart-five';
import LineChartFour from '../charts/line-chart-four';
import LineChartSix from '../charts/line-chart-six';
import { AuthContext } from '../../context/AuthContext';

import Header from '../header/header';
import DateRangeFilter from '../date-range-filter/date-range-filter';
const pChartsParser = require('peacock-charts-sdk')
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '370px',
  color: theme.palette.text.secondary,
}));

function removeTokenAndReload() {
  localStorage.removeItem("token")
  window.location.reload()
}
const dateToday = new Date()

function Dashboard() {
  const { token } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(new Date(dateToday.getTime() - (6 * 24 * 60 * 60 * 1000)));
  const [endDate, setEndDate] = useState(dateToday);
  const [params, setParams] = useState({
    frequency: 'daily',
    conversion_currency_code: 'usd',
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  })
  const [chart1Data, setChart1Data] = useState(null)
  const [chart1Loading, setChart1Loading] = useState(true)

  const [chart2Data, setChart2Data] = useState(null)
  const [chart2Loading, setChart2Loading] = useState(true)

  const [chart3Data, setChart3Data] = useState(null)
  const [chart3Loading, setChart3Loading] = useState(true)

  const [chart4Data, setChart4Data] = useState(null)
  const [chart4Loading, setChart4Loading] = useState(true)

  const [chart5Data, setChart5Data] = useState(null)
  const [chart5Loading, setChart5Loading] = useState(true)

  const [chart6Data, setChart6Data] = useState(null)
  const [chart6Loading, setChart6Loading] = useState(true)

  useEffect(() => {
    pChartsParser.pChart1Turnover('DEV', token, params).then(res => {
      if (res !== null) {
        setChart1Data(res.data_bag)
        setChart1Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart2ChartDailyAov('DEV', token, params).then(res => {
      if (res !== null) {
        setChart2Data(res.data_bag)
        setChart2Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart3ApprovalRateTrendsByProcessor('DEV', token, params).then(res => {
      if (res !== null) {
        setChart3Data(res.data_bag)
        setChart3Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
    pChartsParser.pChart4TransactionByDay('DEV', token, params).then(res => {
      if (res !== null) {
        setChart4Data(res.data_bag)
        setChart4Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })

    pChartsParser.pChart5ChargebacksByDay('DEV', token, params).then(res => {
      if (res !== null) {
        setChart5Data(res.data_bag)
        setChart5Loading(false)
      }
    }).catch(err => setChart5Loading(false))

    pChartsParser.pChart6DailySettledVolumeByProcessor('DEV', token, params).then(res => {
      if (res !== null) {
        setChart6Data(res.data_bag)
        setChart6Loading(false)
      }
    }).catch(err => {
      removeTokenAndReload()
    })
  }, [params])
  return (
    <>
      <Header />
      <Container maxWidth="xl">
        <Card sx={{ marginTop: '16px', padding: '16px 8px' }}>
          <Typography gutterBottom variant="h5" component="div">
            Dashboard
          </Typography>
          <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setParams={setParams} />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item sm={12} md={6} lg={4}>
              {!chart1Loading ? <LineChartOne data={chart1Data} /> : <Item>Loading</Item>}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              {!chart2Loading ? <LineChartTwo data={chart2Data} /> : <Item>Loading</Item>}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              {!chart3Loading ? <LineChartThree data={chart3Data} /> : <Item>Loading</Item>}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              {!chart4Loading ? <LineChartFour data={chart4Data} /> : <Item>Loading</Item>}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              {!chart5Loading ? <LineChartFive data={chart5Data} /> : <Item>Loading</Item>}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              {!chart6Loading ? <LineChartSix data={chart6Data} /> : <Item>Loading</Item>}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>

  );
}

export default Dashboard;
