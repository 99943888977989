import { Button, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';
import { loginCall } from '../../apiCalls';
import { AuthContext } from '../../context/AuthContext';
function Login() {
localStorage.removeItem('token')
const [token, setToken] = useState('');
const { isFetching, dispatch } = useContext(AuthContext);
  const handleSubmit = (event) => {
    if(token) {
        event.preventDefault();
        loginCall({ token: token },dispatch);
    }
  };
  const handleOnChange = (event) => {
    setToken(event.target.value)
  };
  return (
    <Container maxWidth="xl">
      <Card sx={{ marginTop: '16px', padding: '16px 8px', height: '100vh' }}>
        <Typography gutterBottom variant="h5" component="div">
            Auth Token
        </Typography>
        <TextField
                fullWidth
                style={{ marginTop: '16px' }}
                id="token"
                label="Input Token"
                value={token}
                onChange={handleOnChange}
                disabled={isFetching}
            />
        <Button variant="contained" style={{ marginTop: '16px' }} disabled={isFetching} onClick={handleSubmit}>Login</Button>
      </Card>
    </Container>
  );
}

export default Login;
