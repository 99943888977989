import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import uuid from "react-uuid"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

function IssuerChartFour({ data }) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));
  // const { time_series: data } = requestData

  // data.forEach((item) => {
  //   const itemKeys = Object.keys(item)
  //   item.key = uuid()
  //   itemKeys.forEach((key) => {
  //     if (item[key] === undefined || item[key] === null || item[key] === "") {
  //       item[key] = "Unknown"
  //     }
  //   })
  // })

  const [rows, setRows] = useState([])
  
  useEffect(()=>{
    if(data) {
      let newSeries = []
      data.time_series.forEach(item=>{
        const temp = { 
          pm_card_bin: item.pm_card_bin,
          pm_card_issuer_bank: item.pm_card_issuer_bank,
          currency: item.currency, 
          pm_card_issuer_country: item.pm_card_issuer_country, 
          count: item.count, 
          total_amount: item.total_amount, 
          approval_rate:item.approval_rate 
        }
        newSeries.push(temp)
      })
      setRows(newSeries)
    }
  },[])
  return (
    <Item>
      <>
        <Typography gutterBottom variant="h5" component="div">
          Processor Transaction Count Approval
        </Typography>
        <TableContainer component={Paper}  style={{height:'340px'}}>
          <Table sx={{ minWidth: 320 }} aria-label="a dense table" >
            <TableHead>
              <TableRow>
                <TableCell>Bank Identification Number</TableCell>
                <TableCell align="right">Cards Bank Name</TableCell>
                <TableCell align="right">Currency</TableCell>
                <TableCell align="right">Country</TableCell>
                <TableCell align="right">Total Approved Count</TableCell>
                <TableCell align="right">Total Approved Volume</TableCell>
                <TableCell align="right">Approval Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={`${row.pm_card_bin}-${Math.random()}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.pm_card_bin}
                  </TableCell>
                  <TableCell align="right">{row.pm_card_issuer_bank}</TableCell>
                  <TableCell align="right">{row.currency}</TableCell>
                  <TableCell align="right">{row.pm_card_issuer_country}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                  <TableCell align="right">{row.total_amount}</TableCell>
                  <TableCell align="right">{row.approval_rate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Item>
  );
}

export default IssuerChartFour;
