import { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import { dayMonthFormattor, kFormatter } from '../../utils';
import * as echarts from "echarts"
import { Typography } from '@mui/material';

const gradientStyle = {
  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    offset: 0,
    color: "rgba(24, 144, 255, 0.6)"
  }, {
    offset: 1,
    color: "rgba(24, 144, 255, 0)"
  }])
}
function LineChartOne({data}) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '370px',
    color: theme.palette.text.secondary,
  }));

  const [total, setTotal] = useState("")
  const [chartOptions, setChartOptions] = useState({
    grid: { top: 40, right: 8, bottom: 24, left: 36 },
    axisPointer: {
      lineStyle: {
        color: "rgba(24, 144, 255, 0.4)"
      }
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value, index) {
          return kFormatter(value);
        }
      }
    },
    series: [
      {
        data: [],
        type: 'line',
        areaStyle: gradientStyle,
        smooth: true
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  })

  useEffect(() => {
        if(data) {
            setTotal(data.trends[0].compare_trend_1_turn_over)
            let xValues = []
            data.time_series.forEach(item => {
            xValues.push(dayMonthFormattor(item.date))
            })
            let yValues = []
            data.time_series.forEach(item => {
            yValues.push(Math.floor(item.total_amount))
            setChartOptions({
                ...chartOptions,
                xAxis: {
                    type: 'category',
                    data: xValues,
                },
                series: [
                    {
                      data: yValues,
                      type: 'line',
                      areaStyle: gradientStyle,
                      smooth: true
                    }
                ]
            })
        })
        }
  }, [data])
  return (
    <Item>{chartOptions ? <>
        <Typography gutterBottom variant="h5" component="div">
        Turnover US$ {kFormatter(total)} 
      </Typography>
      <ReactECharts option={chartOptions} />
      </> : 'No Data'}</Item>
  );
}

export default LineChartOne;
