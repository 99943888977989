import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AuthContext } from '../../../context/AuthContext';
import DateRangeFilter from '../../date-range-filter/date-range-filter';
import StoredCredentialsChartSix from './stored-credentials-chart-six';
import StoredCredentialsChartFive from './stored-credentials-chart-five';
import StoredCredentialsChartFour from './stored-credentials-chart-four';
import StoredCredentialsChartThree from './stored-credentials-chart-three';
import StoredCredentialsChartTwo from './stored-credentials-chart-two';
import StoredCredentialsChartOne from './stored-credentials-chart-one';

const pChartsParser  =  require('peacock-charts-sdk')
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '370px',
  color: theme.palette.text.secondary,
}));
function removeTokenAndReload() {
  localStorage.removeItem("token")
  window.location.reload()
}
const dateToday = new Date()
function StoredCredentials() {

  const { token } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(new Date(dateToday.getTime() - (6 * 24 * 60 * 60 * 1000)));
  const [endDate, setEndDate] = useState(dateToday);
  const [chart1Data, setChart1Data] = useState(null)
  const [chart1Loading, setChart1Loading] = useState(true)
  const [params, setParams] = useState({
    frequency: 'daily',
    conversion_currency_code: 'usd',
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  })
  const [chart2Data, setChart2Data] = useState(null)
  const [chart2Loading, setChart2Loading] = useState(true)

  const [chart3Data, setChart3Data] = useState(null)
  const [chart3Loading, setChart3Loading] = useState(true)
  
  const [chart4Data, setChart4Data] = useState(null)
  const [chart4Loading, setChart4Loading] = useState(true)

  const [chart5Data, setChart5Data] = useState(null)
  const [chart5Loading, setChart5Loading] = useState(true)

  const [chart6Data, setChart6Data] = useState(null)
  const [chart6Loading, setChart6Loading] = useState(true)
  useEffect(() => {
    pChartsParser.pChart90ApprovalRateByIntent('DEV',token,params).then(res => {
      if (res !== null) {
        setChart1Data(res.data_bag)
        setChart1Loading(false)
      }
    }).catch(err=> {
      removeTokenAndReload()
    })
    pChartsParser.pChart91IntentTransactionsCount('DEV',token,params).then(res => {
      if (res !== null) {
        setChart2Data(res.data_bag)
        setChart2Loading(false)
      }
    }).catch(err=> {
      removeTokenAndReload()
    })
    pChartsParser.pChart92IntentsSuccessfulTransactionVolume('DEV',token,params).then(res => {
      if (res !== null) {
        setChart3Data(res.data_bag)
        setChart3Loading(false)
      }
    }).catch(err=> {
      removeTokenAndReload()
    })
    pChartsParser.pChart93IntentSuccessfulTransactionNetworkMix('DEV',token,params).then(res => {
      if (res !== null) {
        setChart4Data(res.data_bag)
        setChart4Loading(false)
      }
    }).catch(err=> {
      removeTokenAndReload()
    })

    pChartsParser.pChart94DeclineCodesByIntent('DEV',token,params).then(res => {
      if (res !== null) {
        setChart5Data(res.data_bag)
        setChart5Loading(false)
      }
    }).catch(err=> {
      removeTokenAndReload()
    })

    pChartsParser.pChart95IssuerCountryTransactionStatusCount('DEV',token,params).then(res => {
      if (res !== null) {
        setChart6Data(res.data_bag)
        setChart6Loading(false)
      }
    }).catch(err=> {
      removeTokenAndReload()
    })
  }, [params])
  return (
    <Container maxWidth="xl">
      <Card sx={{ marginTop: '16px', padding: '16px 8px' }}>
        <Typography gutterBottom variant="h5" component="div">
            Stored Credentials
        </Typography>
        <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setParams={setParams} />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6} lg={4}>
            {!chart1Loading? <StoredCredentialsChartOne data={chart1Data}/> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart2Loading? <StoredCredentialsChartTwo data={chart2Data}/> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart3Loading? <StoredCredentialsChartThree data={chart3Data}/> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart4Loading? <StoredCredentialsChartFour data={chart4Data}/> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart5Loading? <StoredCredentialsChartFive data={chart5Data}/> : <Item>Loading</Item>}
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            {!chart6Loading? <StoredCredentialsChartSix data={chart6Data}/> : <Item>Loading</Item>}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

export default StoredCredentials;
